.sda__header {
  display: flex;
  z-index: 1;
  background: var(--color-footer);
  width: 100%;
  align-items: flex-end;
  position: -webkit-sitcky;
  position: sticky;
  top: 0;
  left: 0;
}
.welcome-header-buttons{
  display: flex; 
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.toEnd {
  display: flex;
}
.desktop{
  margin: 1.5rem 1rem;
}
.sda__navbar-links {
  display: flex;
  align-items: flex-end;
  width: 100%;
  flex: 1;
  justify-content: space-between;
  padding: 1rem;
}
.sda__navbar-links_logo {
  display: flex;
  width: 90px;
  height: 120px;
  padding: 0.5rem;
}
.sda__navabar-links img {
  width: 90px;
  height: 120px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}
.sda__navbar-links_container {
  display: flex;
  justify-content: space-between;
  margin: 0rem 1rem;
}
.sda__navbar-links_container p {
  display: flex;
  padding: 1rem;
  align-items: center;
  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
}
a:hover {
  color: var(--color-footer);
}
.sda__navbar-sign {
  display: flex column;
  align-self: flex-end;
}
.sda__navbar-menu {
  display: none;
}
.signup_button-mobile{
  text-align: center;
  border: 5px solid var(--peach);
  background-color: #204531;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  align-self: center;
  color: var(--peach);
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  width: 130px;
  height: 75px;
  outline: none;
  margin-bottom: 1rem;
}
.signin_button-mobile{
  text-align: center;
  border: 5px solid var(--peach);
  background-color: #0f44a1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  align-self: center;
  color: var(--peach);
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  width: 130px;
  outline: none;
  height: 75px;
}
@media screen and (max-width: 1050px) {
  .sda__navbar-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem;
    z-index: 2;
    position:relative;
  }
  .sda__navbar-menu svg{
    display: flex;
    align-self: flex-end;
    color: var(--peach);
  }
  .sda__navbar-sign {
    display: none;
  }

  .sda__navbar-sign-in{
    display: none;
  }
  .sda__navbar-links_container {
    display: none;
  }
  .sda__navbar-menu-container {
    display: flex column;
    background: var(--color-footer);
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: absolute;
    overflow: show;
    height: 100vh;
    width: 50vw;
    top:70px;
    left: -115px;
    padding: 1rem;
  }
  .sda__navbar-menu-sign-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .sda__navbar-menu-container-links-sign {
    justify-content: space-between;
  }
  .sda__navbar-menu-container p {
    color: white;
    font-weight: bold;
    font-size: 23px;
    padding: 1rem;
    text-align: center;
    cursor: pointer;
    position: relative;
  }
  .slide-out-right {
    -webkit-animation: slide-out-right 300ms cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
            animation: slide-out-right 300ms cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
  }

  .sda__navbar-menu-container button:hover {
    color: var(--color-subtext);
  }
  .sda__navbar-menu-container-sign{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
#sign-container {
  transition: translate 1s;
}
@media screen and (max-width: 1054px) {
  .toEnd{
    display:none;
  }
}
@media screen and (max-width: 375px) {
  .sda__navbar-links_logo {
    display: flex;
    width: 60px;
    height: 80px;
    padding: 0.5rem;
  }
  .sda__navabar-links img {
    width: 60px;
    height: 80px;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  }
}/* ----------------------------------------------
 * Generated by Animista on 2022-7-19 18:59:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}

