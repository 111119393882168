  .testimonial {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  height: auto;
  font-weight: 900;
    color:var(--peach);
    justify-content:center;
    align-items: center;
    margin: 4rem 0rem;
}
.testimonial-heading{
  display: flex;
  justify-content: center;
  padding: 1rem;
  font-size: 40px;
  text-align: center;

}
.review-slide-container{
  display: flex;
  width: 100%;
}