.sda__welcome {
  display: flex;
  width: 100%;
  height: 100vh;
  position: relative;
  font-weight: 900;
  background-color: rgba(55, 5, 5, 0.228);
}
.welcome__section {
  display: flex;
  width: 100%;
  margin: 2rem 4rem;
}
.welcome__header {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding: 1rem;
}
.welcome__img {
  display: flex;
  flex: 1;
  justify-content: center;
}
.img-container {
  display: flex;
}
.welcome__img img {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 1rem;
  width: 500px;
}
.sda__welcome_header {
  display: flex;
  flex-direction: column;
  height: auto;
  flex: 2;
}
.welcome__heading {
  font-size: 89px;
  line-height: 98px;
  overflow: display;
  display: flex;
  align-items: center;
  stroke: solid white;
  color: var(--peach);
  border-radius: 10px;
  padding: 1rem;
}
.welcome__subtext {
  color: var(--color-text);
  display: flex;
  font-size: 18px;
  line-height: 30px;
  padding: 0;
  display: flex;
  align-self: flex-start;
  color: var(--peach);
  padding: 1rem;
}
.welcome__signup {
  text-align: center;
  border: 5px solid var(--peach);
  background-color: #204531;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  align-self: center;
  color: var(--peach);
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  width: 130px;
  height: 75px;
  outline: none;
  margin-bottom: 1rem;

}
.welcome__sign-in {
  text-align: center;
  border: 5px solid var(--peach);
  background-color: #0f44a1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  align-self: center;
  color: var(--peach);
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  width: 130px;
  outline: none;
  height: 75px;

}
.welcome-buttons {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
}
.welcome__signup:hover {
}
.background-image {
  background-image: url("../../assets/images/longon_ontario.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  z-index: -1;
  display: block;
  margin-bottom: 4rem;
  overflow: visible;
  width: 100%;
  height: 100%;
  position: absolute;
  filter:brightness(50%) opacity(84%);
}
.welcome__signup:active {
}
@media screen and (max-width: 1054px) {
  .sda__welcome{
    height: auto;
  }
  .welcome__img img {
    height: 60%;
    align-self: center;
  }
  .welcome__heading {
    font-size: 60px;
    line-height: 60px;
    padding: 1rem;
    text-align: center;
  }
  .welcome__subtext {
    font-size: 12px;
    line-height: 20px;
    text-align: center;
  }
  .welcome__signup {
    font-size: 18px;
  }
  .welcome__sign-in {
    font-size: 18px;
  }
  .background-image {
    height: 100vh;

    background-position: center;
    align-self: flex-start;
  }
}
@media screen and (max-width: 768px) {
  .sda__welcome{
    height: 100vh;
  }
  .welcome__section {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  .welcome__heading {
    font-size: 50px;
    line-height: 55px;
    padding: 1rem;
    text-align: center;
  }
  .welcome__subtext {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }
  .welcome__signup {
    font-size: 18px;
  }
  .welcome__img{
    display: none;
  }
}
@media screen and (max-width: 375px) {
  .sda__welcome{
    height: auto;
  }
  .welcome-buttons{
    margin:0;
    flex-direction: row;
    justify-content: center;
    align-items: center;

  }
  .welcome__section {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  .welcome__signup, .welcome__sign-in{
    margin:1.5rem 1rem;
  }
  .welcome__heading {
    font-size: 50px;
    line-height: 55px;
    padding: 1rem;
    text-align: center;
  }
  .welcome__subtext {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }
  .welcome__signup {
    font-size: 18px;
  }
  .welcome__img{
    display: none;
  }
  }

