@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,807;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --font-family: "Montserrat", sans-serif;

  --gradient-text: linear-gradient(177deg, rgba(156,48,48,1) 0%, rgba(171,111,111,1) 77%, rgba(185,166,166,1) 100%);
  

--gradient-bar: linear-gradient(22deg, rgba(255,255,255,1) 0%, rgba(248,231,220,1) 0%, rgba(245,205,205,1) 22%, rgba(245,219,203,1) 61%, rgba(156,48,48,1) 77%);
 

 filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#41521f",GradientType=1);
--color-blog: #7c6a0aff;
--color-bg: #6B7D7D;
--color-footer: linear-gradient(
  180deg,
  hsl(0deg 58% 41%) 15%,
  hsl(358deg 67% 36%) 58%,
  hsl(355deg 81% 30%) 74%,
  hsl(352deg 100% 25%) 84%,
  hsl(357deg 100% 22%) 91%,
  hsl(0deg 100% 20%) 96%,
  hsl(0deg 100% 18%) 99%,
  hsl(0deg 100% 16%) 101%,
  hsl(0deg 100% 14%) 102%,
  hsl(0deg 0% 0%) 100%
);
--color-text: #011638;
--color-subtext: #0D1321;
--auburn: #9c3030;
--phthalo-green: #14281d;
--hunter-green: #355834;
--sage: #c9cba3;
--peach: #ffe1a8;
}