
.testimonial-container {
  display: flex;
  flex-direction: column;
  width: 30rem;
  background:rgba(184, 150, 150, 0.314);
  margin: 1rem 1.5rem;
  border-radius: 40px;
}
.testimonial-title {
  margin: 2rem 0rem;
  display: flex;
  font-size: 89px;
  justify-content: center;
  color: var(--peach);
}
.testimonial-display {
  grid-column-gap: 17px;
  grid-row-gap: 13px;
  width: 100%;
  height: 60%;
  display: flex;
}
.profile-image {
  width: 300px;
  height: 300px;
  border-radius: 50%;
}
.testimonial-name{
  display: flex;
  font-size: 40px;
  justify-content: center;
}
.testimonial-review{
  display: flex;
  padding: 1rem 1.5rem;
  text-align: center;
}
.testimonial-picture{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0rem;
}
