.contact {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  color: var(--peach);
}
.contact-card {
  display: flex column;
}
.contact-container {
  display: flex;
  margin: 4rem 2rem;
  height: 100%;
  width: 100%;
  border-radius: 40px;
  margin: 4rem 2rem;
}
.contact-display-grid {
  width: 100%;
  height: 77%;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 10px;
  border-radius: 40px;
  margin: 4rem 2rem;
}

.contact-display-grid-picture-container {
  grid-area: 1 / 1 / 5 / 3;
  border-radius: 40px;

}
.contact-display-grid__contact-container {
  grid-area: 1 / 3 / 3 / 5;
  border-radius: 40px;
  justify-content:center ;
  align-items: center;
  background: rgba(97, 9, 9, 0.382);
}
.contact-display-grid__student-portal-container {
  grid-area: 3 / 3 / 5 / 5;
  border-radius: 40px;
  background: rgba(97, 9, 9, 0.382);

}
.contact-display-grid-picture{
    display: flex;
    width:100%;
    height: 100%;
    border-radius: 40px;
    position: relative;
}
.contact-display-grid__contact{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: 40px;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}
.contact-display-grid__student-portal-container{
  display: flex column;
  justify-content: center;
  align-items: ce;
  height: 100%;
}
.contact-display-grid__student-portal{
  display: flex;
  flex-direction: column;
  width: 100%;
  height:100%;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
}
.contact-display-grid__student-portal *{
  display:flex;
}
.contact-picture {
  max-width: 100%;
  width: 100%;
  height: auto;
  border-radius: 40px;
}
.background-image2{
    background: url('../../assets/images/dad.png');
    background-position: center center;
    background-size:cover;
    background-repeat: no-repeat;
    position: absolute;
    z-index: -1;
    height: 100%;
    width:100%;
    border-radius:40px;
    filter:blur(3px);
    filter: brightness(70%);
}
.contact-display-card-heading{
    display: flex;
    height: 100%;
    width:100%;
}
.contact-display-grid-picture h1{
    display:flex;
    align-self: flex-start;
    font-size: 200px;
    width:400px;
    margin: 1rem;
    font-weight: 900;
    color: rgb(242, 233, 166);
}
.contact-block-container{
  display: flex;
  width: 100%;
  justify-content: center;

}
.contact-display-grid__contact-heading{
  font-size:60px;
  font-weight: 900;
}

.portal-button-container{
  display: flex;
  flex: .8;
  align-items: center;
}
.student-portal-heading{
  font-size:60px;
  font-weight: 900;
}
.grid-button{
  width: 200px;
  height: 100px;
  justify-content:center; 
  align-items:center;
  outline: none;
  border-radius: 40px;
  font-size: 40px;
  font-weight: 900;
  text-decoration: none; 
  background-color: rgb(63, 63, 232);
  border: 5px solid var(--peach);
  color: var(--peach)
}
.contact-display-grid__contact-heading{
    display: flex;
}
.contact-section{
    display: flex column;

}
.contact-section-content{
    display: flex;
}
.contact-icon{
    display: flex;
}
.contact-title{
    display:flex;
}
.contact-block-container{
  display: flex;
  justify-content: center;
  margin: 0rem 1rem;
}
.contact-buttons{
  display: flex;
  flex-direction: column;
  margin: 0rem 2rem;
  align-items: center;
  width: 50px;
  font-size: 20px;
}
.contact-icons{
  display: flex;
  font-size:
}


@media screen and (max-width: 768px) {
  .contact-display-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 10px;
    margin: .5rem 1rem;
    }
    
    .contact-display-grid-picture-container { grid-area: 1 / 1 / 2 / 3; }
    .contact-display-grid__contact-container { grid-area: 2 / 1 / 3 / 3; }
    .contact-display-grid__student-portal-container{ grid-area: 3 / 1 / 4 / 3; }
    .contact-display-grid-picture h1{
      font-size:50px;
    }
    .student-portal-heading{
      font-size: 20px;
    }
    .contact-display-grid__contact-heading{
      font-size: 20px;
    }
    .contact-buttons{
      font-size: 12px;
    }
    .grid-button{
      width: 120px;
      height: 60px;
      justify-content:center; 
      align-items:center;
      outline: none;
      border-radius: 20px;
      font-size: 20px;
      font-weight: 900;
      text-decoration: none; 
      background-color: rgb(63, 63, 232);
      border: 5px solid var(--peach);
      color: var(--peach)
    }
}