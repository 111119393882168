.card {
  display: flex;
  flex-direction: colum;
  width: 20rem;
  height:35rem;
    background: rgba(220, 109, 109, 0.368);
  border-radius: 40px;
  z-index: -1;
  padding: 1rem;
}
.card-content {
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;
  align-items: center;
}
.card-content * {
  display: flex;
  color: var(--peach);
}
.card-header {
  justify-content: center;
  align-items: center;
  height: 20%;
}
.card-header h1 {
  font-size: 40px;
  color: var(--peach);
}
.price {
  display: flex;
  height: 15%;
}
.card-price,
.cardprice h1 {
  display: flex;
  font-size: 40px;  font-size: 70px;

}
.features-list, .features-list-list {
  width: 100%;
  flex-direction: column;
  margin-bottom: 3rem;
}
.features-list{
  height: 40%;

}
.features-list-list li{
  font-size: 12px;
  margin-left:1rem;
  margin: .1rem;
  text-align: center;
  justify-content: space-around;
  align-items: center;
}
.features-heading{
  text-align: center;
  justify-content: center;
}
.btn-add-to-cart {
  text-align: center;
  border: 5px solid var(--peach);
  background-color: #202e45;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  align-self: center;
  color: var(--peach);
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  width: 100px;
  height: 55px;
  outline: none;
  margin: 1rem;
}
.swiper {
  z-index: 0;
}
.list-edit{
  display: flex;
  font-size: 40px;
}
.fullPageSlide {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width:376px){
    .card{
      height: 30rem;
    }
  }