.studentportal{
    width:100%;
    height: 100vh;
    display: flex ;
    justify-content: center;
    align-items: center;
    /* font-size: 20px; */
    color: var(--peach);
}
.studentportal-title{
    display: flex;

    text-align: center;
    width:100%;
    justify-content: center;
    font-size: 90px;
}
.portalMessage{
    display: flex;
    justify-content: center;
    font-size: 40px;
    padding:1rem;
}
@media screen and (max-width: 767px) {
    .studentportal{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .studentportal h1 {
         font-size: 30px;
         padding: 1rem;
         margin: 2rem 0rem;
    }
    .studentportal svg {
        align-self: center;
        justify-content: center;
        display: block;
        width: 200px;
    }
    .studentportal-container{
        margin: .5rem 1rem;
        justify-content: center;
    }
}